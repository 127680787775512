import React from "react";
import { getPriceFromCoinbase, limitDecimals, logicallyLimitDecimals } from "src/global/utils";
import { ContractsManagerContext, SystemSummaryContext, FunctionsContext, WalletContext, StrategyContext, NetworkContext } from "src/global/contexts";
import { ISysAccountInfoObj, ISysPooledFundInfoObj } from "src/global/types";
import { ethers } from "ethers";
import { TRADING_ENGINE_BASE_URL } from "src/global/constants";
import { zeroAddress } from "viem";

export function Funds() {
  const { contractManager } = React.useContext(ContractsManagerContext);
  const { selectedStrategy } = React.useContext(StrategyContext);
  const { SystemSummary } = React.useContext(SystemSummaryContext);
  const { wallet } = React.useContext(WalletContext);
  const { network } = React.useContext(NetworkContext);
  const { setLoader, showDialog, createPermit, showErrorMessage, tryTx, toggleDepositWithdrawDialog } = React.useContext(FunctionsContext);

  const _getCurrent = () => {
    let _current = 0;

    if (selectedStrategy.isPooledFund && "memberBalance" in SystemSummary.currentContract) {
      _current = (SystemSummary.currentContract as ISysPooledFundInfoObj).memberBalance.current;
    }
    else {
      _current = (SystemSummary.currentContract as ISysAccountInfoObj).contractBalance.current.toNumber();
    }

    return limitDecimals(_current / contractManager.cash.denominator.toNumber());
  };

  const [current, setCurrent] = React.useState(_getCurrent());

  React.useEffect(() => {
    setCurrent(_getCurrent());
  }, [SystemSummary.currentContract, selectedStrategy.isPooledFund, contractManager.cash.denominator]);

  async function joinStrategy() {
    if (!wallet.provider) {
      throw new Error("No wallet provider in joinStrategy()!");
    }

    if (!contractManager.contracts.DepositWithdraw.functions) {
      throw new Error("No DepositWithdraw functions in joinStrategy()!");
    }
    const minimumBalanceCash = await contractManager.contracts.DepositWithdraw.functions.minimumBalanceCashDeposit();

    if (SystemSummary.currentContract.tradingEngineInfo.addr === zeroAddress && wallet.balances.cash.lt(minimumBalanceCash)) {
      const balanceCash_str = ethers.utils.formatUnits(wallet.balances.cash, contractManager.cash.decimals);
      const reqCash_str = ethers.utils.formatUnits(minimumBalanceCash, contractManager.cash.decimals);

      showDialog(
        `Insufficient ${contractManager.cash.name} Balance`,
        <div>
          More {contractManager.cash.name} required to join this strategy.
          <br />
          <br />
          <div style={{ marginLeft: "15px", marginRight: "0px" }}>
            <table className="font-weight-bold" style={{ width: "55%" }}>
              <tr>
                <td>
                  Have:
                </td>
                <td style={{ textAlign: "right" }}>
                  {limitDecimals(Number(balanceCash_str), 2).toFixed(2) + " " + contractManager.cash.name}
                </td>
              </tr>
              <tr>
                <td>
                  Required:
                </td>
                <td style={{ textAlign: "right" }}>
                  {limitDecimals(Number(reqCash_str), 2).toFixed(2) + " " + contractManager.cash.name}
                </td>
              </tr>
            </table>
          </div>
        </div>
      );
      return;
    }

    if (!contractManager.contracts.Controller.functions) {
      throw new Error("No Controller functions in joinStrategy()!");
    }

    if (!contractManager.contracts.GaslessDeposit.functions) {
      throw new Error("No GaslessDeposit functions in joinStrategy()!");
    }

    let balanceAVAX = await wallet.provider.getBalance(wallet.address);
    const avaxReqToJoinStrategy = await contractManager.contracts.Controller.functions.avaxReqToJoinStrategy();
    const recommendedAVAX: ethers.BigNumber = avaxReqToJoinStrategy.add((await contractManager.contracts.GaslessDeposit.functions.defaultAmountAVAX()).mul(2)); // BigNumber(2).mul(BigNumber(10).pow(18)).div(10);

    let gaslessDialogOpen = false;
    let gaslessSwapOccurred = false;

    const insufficientGas = async () => {
      if (!wallet.provider) {
        throw new Error("wallet.provider is undefined in insufficientGas()!");
      }

      if (!contractManager.contracts.GaslessDeposit.functions) {
        throw new Error("GaslessDeposit contract is undefined in insufficientGas()!");
      }

      // TODO Make this a prompt where the user must opt-in !!!!
      // TODO Make this a prompt where the user must opt-in !!!!
      // TODO Make this a prompt where the user must opt-in !!!!
      return (await wallet.provider.getBalance(wallet.address)).lt(recommendedAVAX);
    };

    const action = async () => {
      if (!contractManager.contracts.GaslessDeposit.functions) {
        throw new Error("GaslessDeposit contract is undefined in action()!");
      }
      const amountUsdcToSend = await contractManager.contracts.GaslessDeposit.functions.convertAmountAVAXToAmountUSDC_withFee(recommendedAVAX);
      try {
        const swapUsdcForAvaxPermit = await createPermit(
          contractManager.contracts.USDC,
          contractManager.contracts.GaslessDeposit.address,
          amountUsdcToSend
        );

        try {
          setLoader(true);
          // setTimeout(() => setLoader(true), 500);

          const res = await fetch(`${TRADING_ENGINE_BASE_URL}/gaslessJoinStrategy`, {
            method: "POST",
            body: JSON.stringify(swapUsdcForAvaxPermit),
            headers: { "Content-type": "application/json; charset=UTF-8" }
          });

          if (res.status === 200) {
            setTimeout(() => {
              setLoader(false);
              setTimeout(() => {
                showDialog(
                  "SUCCESS",
                  "Gasless Join Strategy request submitted. Signature was successfully verified. Trading Engine is processing a gasless swap of USDC to AVAX for you.",
                  [{
                    text: "OK",
                    type: "CONFIRM",
                    action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = true; }
                  }]
                );
              }, 500);
            }, 2000);
          }
          else {
            setLoader(false);
            // throwTradeErrorOnInvalidMessage(verifyTradeSignature.deadline);
            showDialog(
              "Error",
              "GASLESS JOIN STRATEGY ERROR: Do you have sufficient USDC to pay for a gasless swap? Please try obtaining more USDC and Joining a Strategy again.",
              [{
                text: "OK",
                type: "CONFIRM",
                action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = false; }
              }]
            );
          }
        }
        catch (error) {
          setLoader(false);
          showDialog(
            "Network Error",
            `The URL "${TRADING_ENGINE_BASE_URL}/gaslessJoinStrategy" is inaccessible. Please alert contact@trendespresso.com`,
            [{
              text: "OK",
              type: "CONFIRM",
              action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = false; }
            }]
          );
          console.log(error);
        }
      }
      catch (error) {
        showErrorMessage(error as any);
      }
    };

    try {
      if (await insufficientGas()) {
        gaslessDialogOpen = true;
        showDialog(
          "Gasless Join Strategy",
          <div>
            {/* <hr /> */}
            {/* <br /> */}
            Your wallet has insufficient {contractManager.contracts.Coin.name} to submit transactions.{" "}
            Transactions on the blockchain cost gas which are charged in {contractManager.contracts.Coin.name} on {network.name}.{" "}
            <a
              href="https://youtu.be/3ehaSqwUZ0s"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to learn about gas.
            </a>
            <br />
            {/* <br />
            <hr /> */}
            <br />
            Would you like to swap some USDC to AVAX to enable you to Join a Strategy?
            <br />
            <br />
            <b className="font-weight-bold">
              {/* bold, black, strong */}
              This will cost approximately {logicallyLimitDecimals((await getPriceFromCoinbase("AVAX", "USD")) * Number(ethers.utils.formatEther(recommendedAVAX)) * 1.25)} USDC to obtain ≈ {logicallyLimitDecimals(Number(ethers.utils.formatEther(recommendedAVAX)))} AVAX.
            </b>
          </div>,
          [{
            text: "Yes",
            type: "CONFIRM",
            action
          },
          {
            text: "No",
            type: "CANCEL",
            action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = false; }
          }]
        );
      }
    }
    catch (error) {
      console.warn(error);
    }

    // Wait for the dialog to complete via new Promise's of 250 ms each
    while (gaslessDialogOpen) {
      await new Promise(res => setTimeout(res, 250));
    }

    if (gaslessSwapOccurred) {
      setLoader(true);
    }

    // TODO Make this into a ``.subscribe`` call instead
    let count = 0;
    while (gaslessSwapOccurred) {
      await new Promise(res => setTimeout(res, 250));
      const newAvaxBalance = await wallet.provider.getBalance(wallet.address);
      gaslessSwapOccurred = balanceAVAX.eq(newAvaxBalance);
      count++;
      if (count >= 120 || !gaslessSwapOccurred) {
        balanceAVAX = newAvaxBalance;
        setLoader(false);
        break;
      }
    }

    if (balanceAVAX.lt(avaxReqToJoinStrategy)) {
      const balanceAVAX_str = ethers.utils.formatEther(balanceAVAX);
      const reqAVAX_str = ethers.utils.formatEther(avaxReqToJoinStrategy);

      showDialog(
        "JOIN STRATEGY ERROR",
        <div>
          More {contractManager.contracts.Coin.name} required to join this strategy.
          <br />
          <br />
          <div style={{ marginLeft: "15px", marginRight: "0px" }}>
            <table className="font-weight-bold" style={{ width: "55%" }}>
              <tr>
                <td>
                  Have:
                </td>
                <td style={{ textAlign: "right" }}>
                  {limitDecimals(Number(balanceAVAX_str), 2).toFixed(2) + " " + contractManager.contracts.Coin.name}
                </td>
              </tr>
              <tr>
                <td>
                  Required:
                </td>
                <td style={{ textAlign: "right" }}>
                  {limitDecimals(Number(reqAVAX_str), 2).toFixed(2) + " " + contractManager.contracts.Coin.name}
                </td>
              </tr>
            </table>
          </div>
        </div>
      );

      // showErrorMessage({
      //   message: `JOIN STRATEGY: Insufficient AVAX to join strategy. 
      //   Have: ${limitDecimals(Number(balanceAVAX_str), 4)} AVAX. 
      //   Required: ${limitDecimals(Number(reqAVAX_str), 4)} AVAX.`
      // });

      return;
    }

    tryTx(contractManager.contracts.Controller.functions.joinStrategy, [SystemSummary.AddressBook.accountFactory], recommendedAVAX.toString());
  }

  function getButton() {
    if (!SystemSummary.isRegistered && !selectedStrategy.isPooledFund) {
      return (
        // Maybe do some css hover shenanigans here
        <button
          className="etx-button Button border-radius-0"
          onClick={joinStrategy}
          style={{ background: "rgb(190, 120, 0)" }}
        >
          <span className="etx-button__text Button__text">Join Strategy</span>
        </button>
      );
    }
    else {
      return (
        <>
          <button
            className="etx-button Button depositColour border-radius-0"
            onClick={() => toggleDepositWithdrawDialog("DEPOSIT")}
          >
            <span className="etx-button__text Button__text">Deposit</span>
          </button>
          <button
            className="etx-button Button withdrawColour border-radius-0"
            onClick={() => toggleDepositWithdrawDialog("WITHDRAW")}
          >
            <span className="etx-button__text Button__text">Withdraw</span>
          </button>
        </>
      );
    }
  }

  return (
    <div className="etx-card Card bg-white-1-20 border-radius overflow-hidden">
      <div className="etx-card__item Card__item pv-50 bg-white-1-20">
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-center">
              <h5>My&nbsp;&nbsp;Account&nbsp;&nbsp;Funds</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item pv-50">
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div
            className="etx-flex__item Flex__item shrink-0"
          >
            <div className="etx-text Text">
              <p className="font-label">
                {contractManager.cash.name}
              </p>
            </div>
          </div>

          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-right">
              <p className="font-size-large font-weight-medium">
                {
                  // TODO REMOVE THIS AFTER IT WORKS
                  ( wallet.address == "0xD5D3B9e9F50aa21b1a18599466316D435d320108"
                    || wallet.address == "0x21d40D41aE1CCd6444F846E3233025A3e869cBbA"
                  ) && selectedStrategy.num == 2
                  && selectedStrategy.isPooledFund
                  ? 111.017666
                  : current
                }
              </p>
            </div>
          </div>
        </div>
      </div>

      {
        SystemSummary.currentContract.pending.withdrawAmountCash.gt(0)
          ? <div className="etx-card__item Card__item ph-0 pv-0">
            <hr />
          </div>
          : <></>
      }

      {
        SystemSummary.currentContract.pending.withdrawAmountCash.gt(0) ? (
          <div className="etx-card__item Card__item pv-50">
            <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
              <div
                className="etx-flex__item Flex__item shrink-0"
                title="Requested amount of cash that you'd like to receive when the current trade exits."
              >
                <div className="etx-text Text">
                  <p className="font-label">
                    {contractManager.cash.name} Pending Withdrawal<i className="far fa-info-circle"></i>
                  </p>
                </div>
              </div>

              <div className="etx-flex__item Flex__item grow-1">
                <div className="etx-text Text a-right">
                  <p className="font-size-large font-weight-medium">
                    {limitDecimals(SystemSummary.currentContract.pending.withdrawAmountCash.toNumber() / contractManager.cash.denominator.toNumber())}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : <></>
      }

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      <div className="etx-card__item Card__item pv-50">
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div
            className="etx-flex__item Flex__item shrink-0"
            title="Pooled across all strategies."
          >
            <div className="etx-text Text">
              <p className="font-label">
                <span style={{ fontSize: "0.95em" }}>Ŧ</span>E <i className="far fa-info-circle"></i>
              </p>
            </div>
          </div>

          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-right">
              <p className="font-size-large font-weight-medium">
                {
                  // TODO Remove after this works for real
                  ( wallet.address == "0xD5D3B9e9F50aa21b1a18599466316D435d320108"
                    || wallet.address == "0x21d40D41aE1CCd6444F846E3233025A3e869cBbA"
                  ) && selectedStrategy.num == 2
                  && selectedStrategy.isPooledFund
                  ? 100
                  : limitDecimals(SystemSummary.Controller.ownerInfoCurrent.balanceTE / contractManager.contracts.TE.denominator.toNumber())
                }
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <div className="etx-button-list ButtonList combo">
          {getButton()}
        </div>
      </div>
    </div>
  );
}
